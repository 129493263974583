import React from 'react'

const Tags = ({tags}) => {
    return (
        <ul className="tags">
            {tags.map((tag, i) => {
                return (
                    <li className="tag" key={i}>{tag}</li>
                )
            })}
        </ul>
    )
}

export default Tags
