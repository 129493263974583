import { Link } from 'gatsby';
import React, { useEffect } from 'react'
import SEO from '../components/seo'
import Tags from '../components/tags'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const BlogTemplate = ( {data}) => {
  const { markdownRemark: post } = data;

  const blogPostStyle = () => {
    if (post.frontmatter.stylesheet) {
      return ['blog-page', post.frontmatter.stylesheet]
    }
    return ['blog-page']
  } 

  useEffect(() => {
    document.body.classList.add(...blogPostStyle());
    return () => {
      document.body.classList.remove(...blogPostStyle());
    }
  }, [])

  useEffect(() => {
    let blogLink = document.getElementsByClassName('page-link blog')
    blogLink[0].classList.add('current')
    return () => {
      blogLink[0].classList.remove('current')
    }
  }, [])

  
  return (
      <>
      <SEO title={post.frontmatter.title} /> 
      <article className="blog-post-container">
        <div className="blog-post-header">
          <Link to={'/blog/'} className="blog-post-back"> <FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
        </div>
          <h2>{post.frontmatter.title}</h2>
        <h3>{post.frontmatter.date}</h3>
        {post.frontmatter.tags &&
          <Tags tags={post.frontmatter.tags} />
        }
          <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: post.html }}></div>
      </article>
      </>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        tags
        stylesheet
      }
    }
  }
`;

export default BlogTemplate